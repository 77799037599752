import React from "react";
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button, ButtonGroup } from "reactstrap";

function Proyecto() {
    
  return (
    <>
      <div id="nosotros" className="section section-feature cd-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto left pb-5" md="8">
                <h2 className="title">Nosotros</h2>
                <h5 className="description">
                La nube 360 es una agencia de comunicación digital con 15 años de trayectoria y más de 2.500 proyectos ejecutados.
                </h5>
                <h5 className="description">
                Nos hemos especializado en Recorridos Virtuales avanzados, estamos constantemente desarrollando herramientas innovadoras de alta calidad técnica y visual siendo pioneros en el mercado.
                </h5>
                <h5 className="description">
                  Utilizamos las nuevas tecnologías de información y comunicación con el fin de darle valor agregado a las estrategias de marketing digital y acelerar los procesos de comercialización.
                </h5>
                <h5 className="description">
                Trabajamos en Paraguay, Chile, Uruguay, Perú y Argentina y estamos asociados con Pilotos de Drone de todo América lo que nos permite rápidos tiempos de ejecución.
                </h5>
                <ButtonGroup className="d-block text-center">
                  <Button
                    className="btn-round"
                    color="info"
                    to="/#servicios"
                    tag={Link}
                    size="lg"
                  >
                    Servicios
                  </Button>
                </ButtonGroup>
            
              </Col>
            </Row>
        </Container>
      </div>
    </>
  );
}
export default Proyecto;
