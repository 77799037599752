/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

import LogoInstagram from "components/Icons/Instagram";
// core components

function FooterBlack() {
  return (
    <>
      <footer className="footer footer-white">
        <Container>
          <Row className="d-flex justify-content-center">
            <a className="pl-2 py-2" href="https://www.instagram.com/#">
              <LogoInstagram width={36} height={36}/>
            </a>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlack;
