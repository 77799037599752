import React from "react";
// reactstrap components

import Mail from "../Icons/Mail";
import Formulario from "./Formulario.js";
import { Card, CardTitle, Container, Row, Col } from "reactstrap";
import Logo from '../../assets/images/logo.png'
import LogoPuntaAguila from '../../assets/images/logo-punta-aguila.jpg'
import UserCFrame3 from "../Icons/UserCFrame3";
import MobilePhone from "../Icons/MobilePhone";
import SquarePin from "components/Icons/SquarePin";
import LogoInstagram from "components/Icons/Instagram";

function SectionContactUs() {
  
  return (
    <>
      <div className="section section-contactus cd-section" id="contact-us">
        <div
          className="contactus-1 section-image"
          style={{
            backgroundImage:
              "url(" + require("assets/images/sections/contacto.jpg") + ")",
          }}
        >
          <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="10">
            <Card className="card-contact no-transition">
              <CardTitle className="text-center" tag="h3">
                Contáctanos
              </CardTitle>
              <Row>
                <Col className="ml-auto" md="5">
                  <div className="pl-3 pl-md-0">
                    <div className="d-flex justify-content-start justify-content-md-around align-items-center mt-4">
                      <img src={Logo} style={{height:'50px'}} alt="logo-lanube"/>
                      <h4 className="info-title pl-1 pl-md-3 mt-0">Información de Ventas</h4>
                    </div>
                    <div className="pt-3 pl-3 pl-md-0">
                      <div className="pt-3">
                        <UserCFrame3 width="32" height="32"/>
                        <span style={{color:'#333333'}} className="pl-2">Mauricio Galleguillos</span>
                      </div>
                      <div className="pt-3">
                        <MobilePhone width="32" height="32"/>
                        <a className="pl-2" href="tel:+595972596880">
                          +595 972 596 880
                        </a>
                      </div>
                      {/* <div className="pt-3">
                        <Mail width="32" height="32"/>
                        
                        <a className="pl-2 vertical-align-center" href="maito:mgalleguillos@ipasa.com.py">
                          mgalleguillos@ipasa.com.py
                        </a>
                      </div> */}
                      <div className="pt-3">
                        <SquarePin width="32" height="32"/>
                        <a className="pl-2 vertical-align-center" href="/#">
                          Juan XXIII #1815 Edificio Park Plaza of 8G
                        </a>
                      </div> 
                      <div className="pt-3">
                        <LogoInstagram width="32" height="32"/>
                        <a className="pl-2 vertical-align-center" href="/#">
                          @lanube360py
                        </a>
                      </div>
                      <div className="pt-3 ">
                        <div className="w-100 d-flex justify-content-center">
                          <img src={LogoPuntaAguila} style={{width:'130px'}} alt='logo-punta'/>
                        </div>
                      </div>  
                    </div>
                  </div>
                  <hr />
                </Col>
                <Col className="mr-auto" md="5">
                  <Formulario/>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
        </div>
      </div>
    </>
  );
}

export default SectionContactUs;
