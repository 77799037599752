import React from 'react';

function UserCFrame3({width, height}) {
	return (
		<svg height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g fill="#002747" stroke="#002747" strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="2">
                <path d="M38,40.971v-1.489 c0-2.153-1.154-4.141-3.023-5.209l-5.971-3.425" fill="none" strokeLinecap="butt"/>
                <path d="M18.995,30.847l-5.971,3.425 C11.154,35.341,10,37.329,10,39.482v1.489" fill="none" strokeLinecap="butt"/>
                <path d="M24,33L24,33 c-4.418,0-8-4.582-8-9v-4c0-4.418,3.582-8,8-8h0c4.418,0,8,3.582,8,8v4C32,28.418,28.418,33,24,33z" fill="none"/>
                <circle cx="24" cy="24" fill="none" r="22" stroke="#002747"/>
            </g>
        </svg>
	);
};

export default UserCFrame3;