import React from 'react'
import { Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap'
import MapPin from 'components/Icons/MapPin'
import Drone2 from 'components/Icons/Drone2'
import VrHeadset from 'components/Icons/VrHeadset'

export const Servicios = () => {
  return (
    <div id="servicios" className='section section-feature cd-section'>
        <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center pb-3" md="8">
            <h2 className="title">Servicios</h2>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <div >
              <div className='text-center'>
                <MapPin width="48" height="48"/>

              </div>
              <div className="description">
                <h4 className="info-title text-center pb-3">Masterplan 360 Loteos</h4>
                <ListGroup flush>
                    <ListGroupItem style={{borderTop:0}}>
                        Herramienta de alta calidad técnica y visual para promocionar proyectos de Loteos en la web y rrss.
                    </ListGroupItem>
                    <ListGroupItem>
                        Acelera procesos de comercialización y de reserva de lotes.
                    </ListGroupItem>
                    <ListGroupItem>
                        Dinámica e interactiva: actualiza los precios y estados de los lotes.
                    </ListGroupItem>
                    <ListGroupItem>
                        Operamos en todo Paraguay y América.
                    </ListGroupItem>
                </ListGroup>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div>
              <div className='text-center'>
                <Drone2 width="48" height="48"/>
              </div>
              <div className='description'>
                <h4 className="info-title text-center pb-3" >Tour Aeréo Inmobilirias</h4>
                <ListGroup flush>
                      <ListGroupItem style={{borderTop:0}}>
                          Permite visualizar el proyecto y su entorno en 360º.
                      </ListGroupItem>
                      <ListGroupItem>
                          Se destacan puntos de interés y conectividad.
                      </ListGroupItem>
                      <ListGroupItem>
                          Futuras vistas para proyectos en altura.
                      </ListGroupItem>
                      <ListGroupItem style={{borderTop:'1px !important'}}>
                          Herramienta para compartir por RRSS y visualizar en pantallas Touch, páginas web y móviles.
                      </ListGroupItem>
                  </ListGroup>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div>
              <div className='text-center'>
                <VrHeadset width="48" height="48"/>

              </div>
              <div className="description">
                <h4 className="info-title text-center pb-3">Render 360° Piloto Virtual y Espacios Comunes</h4>
                <ListGroup flush>
                    <ListGroupItem style={{borderTop:0}}>
                        Render en 360º para proyectos en verde.
                    </ListGroupItem>
                    <ListGroupItem>
                        Se inserta el mapa de navegación y se conectan vistas 360º entre sí.
                    </ListGroupItem>
                    <ListGroupItem>
                    Aplicación compatible con lentes VR, compartir por RRSS y visualizar en pantallas Touch, páginas web y móviles.
                    </ListGroupItem>
                </ListGroup>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
